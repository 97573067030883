import moment from "moment";
import {
  calculateTimeDifference,
  checkLessThan3Hours,
  equal,
  lowerCase,
  roundToOneDecimal,
  ternary,
} from "./javascript";
import { showToast } from "./toastService";
import {
  convertTimeTo24HourFormat,
  convertTimeToMinutes,
  getyearMonthDateFormat,
} from "./moment";
import { pendingLevels, shiftStatuses } from "./constant";

export const validateOvertime = ({
  breakDiffrenceMins,
  roleId,
  date,
  showTeal,
  businessId,
  globalAttr,
  startTime,
}) => {
  const allRoles = globalAttr?.roles;
  const publicHolidays = globalAttr?.publicHolidays;
  const allBusinesses = globalAttr?.businessDetail;

  const businessState = allBusinesses?.find(
    (cur) => cur?.id === businessId,
  )?.business_state;

  const minHR = allRoles.find(({ id }) =>
    equal(id?.toString(), roleId?.toString()),
  )?.attributes;

  const curDay = moment(date)?.format("dddd");
  const overtimeLimit = minHR?.maximum_shit_hours;

  const breakDiffrenceHours = roundToOneDecimal(breakDiffrenceMins / 60);

  const overTimeMins = breakDiffrenceMins - overtimeLimit * 60;
  const overTimeHours = roundToOneDecimal(overTimeMins / 60);

  const minRate = minHR?.minimum_shit_rate;

  const { isHoliday, holidayName } = isPublicHoliday(
    date,
    publicHolidays,
    businessState,
    showTeal,
  );
  const shiftStartTime = moment(
    `${startTime?.value} ${startTime?.ampm}`,
    "h:mm a",
  );
  const sixPM = moment("6:00 pm", "h:mm a");
  const shouldSkipHolidayToast =
    lowerCase(businessState) === "qld" && shiftStartTime.isBefore(sixPM);

  if (isHoliday) {
    const newOvertimeMin = minRate * 2.5;
    if (showTeal && !shouldSkipHolidayToast) {
      showToast(
        `This shift incur in overtime rates,due to ${holidayName} minimum hourly rate for this shift is $${Math.ceil(
          newOvertimeMin,
        )}`,
        "info",
      );
    }
    return Math.ceil(newOvertimeMin);
  }

  if (equal(curDay, "Sunday")) {
    const newOvertimeMin = minRate * 2;
    showTeal &&
      showToast(
        `This shift incur in overtime rates,due to Sunday minimum hourly rate for this shift is $${Math.ceil(
          newOvertimeMin,
        )}`,
        "info",
      );
    return Math.ceil(newOvertimeMin);
  }

  if (breakDiffrenceMins <= overtimeLimit * 60) {
    return false;
  }
  if (overTimeHours <= 2) {
    const newOvertimeMin =
      (minRate * overtimeLimit + overTimeHours * minRate * 1.5) /
      breakDiffrenceHours;

    showTeal && notifyOvertime(overtimeLimit, Math.ceil(newOvertimeMin));
    return Math.ceil(newOvertimeMin);
  }
  if (overTimeHours > 2) {
    const newOvertimeMin =
      (minRate * overtimeLimit +
        2 * minRate * 1.5 +
        (overTimeHours - 2) * minRate * 2) /
      breakDiffrenceHours;

    showTeal && notifyOvertime(overtimeLimit, Math.ceil(newOvertimeMin));
    return Math.ceil(newOvertimeMin);
  }
  return minRate;
};

export const shiftPostValidate = (
  shiftForm,
  roleId,
  businessId,
  globalAttr,
  viewShiftData,
) => {
  const allBusinesses = globalAttr?.businessDetail;

  const businessState = allBusinesses?.find(
    (cur) => cur?.id === businessId,
  )?.business_state;

  const startDate =
    shiftForm?.start_date &&
    moment(shiftForm?.start_date)?.format("DD/MM/YYYY");
  const currentDate = moment().format("DD/MM/YYYY");
  const time = shiftForm?.time;
  if (equal(startDate, currentDate)) {
    const currentTime = moment()?.format("HH:mm");
    const endTime = convertTimeTo24HourFormat(
      `${time?.endTime?.value} ${time?.endTime?.ampm}`,
    );
    const currentMoment = moment(currentTime, "HH:mm");
    const givenMoment = moment(endTime, "HH:mm");

    const isUnpaid = equal(viewShiftData?.status, shiftStatuses?.unpaid);
    const isPendingUnpaidEdit =
      equal(viewShiftData?.status, shiftStatuses?.pending) &&
      equal(shiftForm?.pending_level, pendingLevels?.unpaidEdit);

    const checkAfterCurrentTime = viewShiftData?.status
      ? !(isUnpaid || isPendingUnpaidEdit) && currentMoment.isAfter(givenMoment)
      : currentMoment.isAfter(givenMoment);

    if (checkAfterCurrentTime) {
      showToast(
        "The shift's end time must be later than the current time. Please choose a valid end time.",
      );
      return false;
    }
  }
  if (checkLessThan3Hours(time?.startTime, time?.endTime, shiftForm?.break)) {
    showToast(
      "Minimum shift duration must be at least 3 hours exlcuding break time.",
    );
    return false;
  }
  const diffrence = calculateTimeDifference(time?.startTime, time?.endTime);
  const breakDiffrence =
    convertTimeToMinutes(diffrence) - Number(shiftForm?.break);

  const NewMinRate = validateOvertime({
    breakDiffrenceMins: breakDiffrence,
    roleId: roleId,
    date: shiftForm?.start_date,
    showTeal: false,
    businessId,
    globalAttr,
    startTime: time?.startTime,
  });

  const shiftStartTime = moment(
    `${time?.startTime?.value} ${time?.startTime?.ampm}`,
    "h:mm a",
  );
  const sixPM = moment("6:00 pm", "h:mm a");
  const shouldSkipHolidayToast =
    lowerCase(businessState) === "qld" && shiftStartTime.isBefore(sixPM);
  if (shiftForm?.rate < NewMinRate) {
    if (!shouldSkipHolidayToast) {
      showToast(
        `The hourly rate set is below the minimum award rate $${NewMinRate}, please ammend.`,
      );
      return false;
    }
  }
  return true;
};

const notifyOvertime = (overtimeLimit, newOvertimeMin) => {
  showToast(
    `Shifts that exceed ${overtimeLimit} hours will now incur overtime rates, minimum hourly rate for this shift is $${roundToOneDecimal(
      newOvertimeMin,
    )}`,
    "info",
  );
};

const isPublicHoliday = (date, publicHolidays, businessState) => {
  const holidayDetail = publicHolidays?.find(
    (cur) =>
      equal(lowerCase(cur?.business), lowerCase(businessState)) &&
      equal(getyearMonthDateFormat(cur?.date), getyearMonthDateFormat(date)),
  );

  return {
    isHoliday: ternary(holidayDetail, true, false),
    holidayName: holidayDetail?.name,
  };
};
